<template>
	<div class="project-container">
		<div class="project-image-container"></div>
		<img class="project-image" :src="topImage" />
		<div class="project-text">
			<h2>Treverksmøbler</h2>
			<p>
                Det finnes utrolig mange møbler å velge i dersom du tar en tur til nærmeste møbelforhandler, likevel kan det være vanskelig å finne de møblene som passer akkurat til deg!
                Ellingsen Byggeservice hjelper deg med å bygge spesialtilpassede treverksmøbler som passer akruatt dine behov og estetiske ønsker - måtte det være et nytt sett med utemøbler til hytta, en integrert TV- og bokhylle eller en ny kommode. 
			</p>
			<p>
				Ta kontakt for en uforpliktende og hyggelig konsultasjon. Kontaktdetaljer finner du <a href="/kontakt">her</a>
			</p>
		</div>
	</div>
</template>

<script>
export default {
	props: ['topImage'],
}
</script>

<style scoped>
.project-image {
	width: 1000px;
	max-width: 70%;
	padding-top: 2%;
}

</style>
